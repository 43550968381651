import { convertSkToWgs84 } from '@tmap-web-lib/utils'
import lt from 'semver/functions/lt'
import { TmapAppInterfaceParams, TmapSearchResult, TmapSearchResultRecord } from './internal-types'
import { TmapApp } from './TmapApp'
import { SearchResult } from './types'
import { callCustomUrl, convertYN, log } from './utils'

export abstract class BaseInterfaceImpl {
  constructor(protected tmapApp: TmapApp) {
  }

  protected selectTimeout(targetAppVersion: string, targetTimeout: number | null, defaultTimeout: number | null = 300) {
    return lt(this.tmapApp.env.appVersion, targetAppVersion) ? defaultTimeout : targetTimeout
  }

  protected get env() {
    return this.tmapApp.env
  }

  protected log(...args: Parameters<typeof log>) {
    return this.tmapApp.utils.log.apply(this.tmapApp, args)
  }

  protected callCustomUrl(...args: Parameters<typeof callCustomUrl>) {
    return this.tmapApp.utils.callCustomUrl.apply(this.tmapApp, args)
  }

  protected decodeCallbackValue(value: string) {
    return JSON.parse(decodeURIComponent(value))
  }

  protected convertSearchResult(data: any[]) {
    if (!data[0]) return null

    let source: TmapSearchResult
    if (this.isVersionBelow('10.10.0')) {
      const [poiId, navSeq, poiName, address, addressType, navX, navY, centerX, centerY] = data as TmapSearchResultRecord
      source = {
        pkey: '',
        poiId,
        navX,
        navY,
        centerX,
        centerY,
        address,
        addressType,
        navSeq,
        poiName,
      }
    } else {
      source = this.decodeCallbackValue(data[0] as string)
    }

    const { navSeq, address, addressType, navX, navY, centerX, centerY, ...restProps } = source
    const nav = convertSkToWgs84({ x: Number(navX), y: Number(navY) })
    const center = convertSkToWgs84({ x: Number(centerX), y: Number(centerY) })

    return {
      ...restProps,
      navSeq,
      address,
      addressType,
      inAddr: address,
      inAddrType: addressType,
      navX: Number(navX),
      navY: Number(navY),
      centerX: Number(centerX),
      centerY: Number(centerY),
      navLon: nav.longitude,
      navLat: nav.latitude,
      centerLon: center.longitude,
      centerLat: center.latitude,
    } satisfies SearchResult
  }

  protected convertOpenInAppBrowserArgs({ bottombar, headerBackKey, portraitOnly, ...args }: TmapAppInterfaceParams['openInAppBrowser']) {
    let directParams = undefined
    if ('directParams' in args) {
      if (this.env.isIOS) {
        directParams = typeof args.directParams === 'string' ? JSON.parse(args.directParams) : args.directParams
      } else {
        directParams = typeof args.directParams === 'string' ? args.directParams : JSON.stringify(args.directParams)
      }
    }
    return {
      ...args,
      directParams,
      bottombar: convertYN(bottombar),
      headerBackKey: convertYN(headerBackKey),
      portraitOnly: convertYN(portraitOnly),
    }
  }

  protected isVersionBelow(version: string) {
    return lt(this.env.appVersion, version)
  }
}
